var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-80 no-select custom-index",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-column bg-adori-gray mh-95 min-w5 br4 shadow-5",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-center justify-between bg-adori-light-gray br3",
            },
            [
              _c("div", { staticClass: "flex silver gap-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "ph3 pv2 pointer grow",
                    class: { bb: _vm.currentContentTab === "STOCK" },
                    on: { click: _vm.openStockTab },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons light-gray f3 v-mid pa1" },
                      [_vm._v("search")]
                    ),
                    _vm._v("Stock library\n          "),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ph3 pv2 pointer grow",
                    class: { bb: _vm.currentContentTab === "LIB" },
                    on: { click: _vm.openLibraryTab },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons light-gray f3 v-mid pa1" },
                      [_vm._v("image")]
                    ),
                    _vm._v("My library\n          "),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ph3 pv2 pointer grow",
                    class: { bb: _vm.currentContentTab === "GENERATE" },
                    on: { click: _vm.openGenerateTab },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons light-gray f3 v-mid pa1" },
                      [_vm._v("auto_awesome ")]
                    ),
                    _vm._v("Generate\n          "),
                  ]
                ),
                _vm.data.length
                  ? _c(
                      "div",
                      {
                        staticClass: "ph3 pv2 pointer grow",
                        class: { bb: _vm.currentContentTab === "BLOG" },
                        on: {
                          click: function ($event) {
                            _vm.currentContentTab = "BLOG"
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f3 v-mid pa1",
                          },
                          [_vm._v("image")]
                        ),
                        _vm._v("Blog Images\n          "),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "i",
                {
                  staticClass: "material-icons white f3 mr3 pointer",
                  on: { click: _vm.closeModal },
                },
                [_vm._v("close")]
              ),
            ]
          ),
          _vm.currentContentTab === "BLOG"
            ? _c(
                "div",
                { staticClass: "masonry-grid mt2 ph3" },
                _vm._l(_vm.data, function (img) {
                  return _c(
                    "div",
                    { staticClass: "relative overflow-x-hidden" },
                    [
                      _c(
                        "div",
                        { staticClass: "img-opt relative hide-child" },
                        [
                          _c("img", {
                            staticClass: "masonry-content ba pointer br2",
                            class:
                              _vm.selectedSceneImage === img
                                ? "ba b--adori-red"
                                : "",
                            attrs: { src: img, download: "" },
                            on: {
                              click: function ($event) {
                                return _vm.selectAiImage(img)
                              },
                            },
                          }),
                          _c(
                            "i",
                            {
                              staticClass:
                                "absolute right-1 bg-black-50 material-icons light-gray f4 v-top pa1 child br3 pointer",
                              staticStyle: { top: "1px", right: "1px" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewImages(img)
                                },
                              },
                            },
                            [_vm._v("\n              visibility\n            ")]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.currentContentTab === "STOCK"
            ? _c("div", { staticClass: "flex gap-1 ph3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ba b--adori-gray br2 mv3 bg-dark-gray relative w-80",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchQuery,
                          expression: "searchQuery",
                        },
                      ],
                      ref: "libTextArea",
                      staticClass:
                        "input-reset bg-adori-light-gray light-gray bn br4 pa2 dib w-100",
                      attrs: {
                        type: "text",
                        placeholder: "Search free images",
                      },
                      domProps: { value: _vm.searchQuery },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.search.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchQuery = $event.target.value
                        },
                      },
                    }),
                    !_vm.searchQuery
                      ? _c(
                          "i",
                          {
                            staticClass:
                              "material-icons white absolute pointer",
                            staticStyle: { top: "5px", right: "8px" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.generateStockAIPrompt.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("auto_awesome")]
                        )
                      : _c(
                          "i",
                          {
                            staticClass:
                              "material-icons silver absolute pointer dim",
                            staticStyle: { top: "6px", right: "2px" },
                            on: { click: _vm.clearSearchQuery },
                          },
                          [_vm._v("close")]
                        ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-adori-red pa2 br2 mv3 pointer w-20 white flex justify-center items-center grow",
                    on: { click: _vm.search },
                  },
                  [_vm._v("\n          Search\n        ")]
                ),
              ])
            : _vm._e(),
          _vm.currentContentTab === "STOCK"
            ? _c("div", { staticClass: "flex items-center ph3" }, [
                _c("div", { staticClass: "mr3 flex items-center pa1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.media,
                        expression: "media",
                      },
                    ],
                    staticClass: "mr2",
                    attrs: { type: "radio", value: "image", id: "image" },
                    domProps: { checked: _vm._q(_vm.media, "image") },
                    on: {
                      change: function ($event) {
                        _vm.media = "image"
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "f5 light-gray pointer",
                      attrs: { for: "image" },
                    },
                    [_vm._v("Image")]
                  ),
                ]),
                _c("div", { staticClass: "mr3 flex items-center pa1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.media,
                        expression: "media",
                      },
                    ],
                    staticClass: "mr2",
                    attrs: { type: "radio", value: "gif", id: "gif" },
                    domProps: { checked: _vm._q(_vm.media, "gif") },
                    on: {
                      change: function ($event) {
                        _vm.media = "gif"
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "f5 light-gray pointer",
                      attrs: { for: "gif" },
                    },
                    [_vm._v("GIF")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSowVideo,
                        expression: "isSowVideo",
                      },
                    ],
                    staticClass: "mr3 flex items-center pa1",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.media,
                          expression: "media",
                        },
                      ],
                      staticClass: "mr2",
                      attrs: { type: "radio", value: "video", id: "video" },
                      domProps: { checked: _vm._q(_vm.media, "video") },
                      on: {
                        change: function ($event) {
                          _vm.media = "video"
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "f5 light-gray pointer",
                        attrs: { for: "video" },
                      },
                      [_vm._v("Video")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.currentContentTab === "LIB"
            ? _c("div", { staticClass: "flex items-center ph3 mt2" }, [
                _c("div", { staticClass: "mr3 flex items-center pa1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.libMedia,
                        expression: "libMedia",
                      },
                    ],
                    staticClass: "mr2",
                    attrs: { type: "radio", value: "image", id: "image" },
                    domProps: { checked: _vm._q(_vm.libMedia, "image") },
                    on: {
                      change: function ($event) {
                        _vm.libMedia = "image"
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "f5 light-gray pointer",
                      attrs: { for: "image" },
                    },
                    [_vm._v("Image")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSowVideo,
                        expression: "isSowVideo",
                      },
                    ],
                    staticClass: "mr3 flex items-center pa1",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.libMedia,
                          expression: "libMedia",
                        },
                      ],
                      staticClass: "mr2",
                      attrs: { type: "radio", value: "video", id: "video" },
                      domProps: { checked: _vm._q(_vm.libMedia, "video") },
                      on: {
                        change: function ($event) {
                          _vm.libMedia = "video"
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "f5 light-gray pointer",
                        attrs: { for: "video" },
                      },
                      [_vm._v("Video")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.currentContentTab == "LIB"
            ? _c(
                "div",
                {
                  staticClass: "ph3",
                  on: {
                    dragover: function ($event) {
                      $event.preventDefault()
                    },
                    drop: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "preven",
                          undefined,
                          $event.key,
                          undefined
                        )
                      ) {
                        return null
                      }
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: { "dark-fill": _vm.bgDragColor },
                      on: {
                        dragover: function () {
                          return (_vm.bgDragColor = true)
                        },
                        dragleave: function () {
                          return (_vm.bgDragColor = false)
                        },
                        click: function ($event) {
                          _vm.libMedia === "image"
                            ? _vm.imageUploader()
                            : _vm.videoUploader()
                        },
                        drop: function ($event) {
                          _vm.libMedia === "image"
                            ? _vm.dropImageFiles($event)
                            : _vm.dropVideoFile($event)
                        },
                      },
                    },
                    [
                      _c("label", { staticClass: "upload" }, [
                        _vm.uploadingImage
                          ? _c("div", [
                              _c("img", {
                                staticClass: "h70",
                                attrs: { src: require("@/assets/spinner.svg") },
                              }),
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.libMedia === "image"
                                        ? "Uploading Image"
                                        : "Uploading Video"
                                    )
                                  ),
                                ]),
                              ]),
                            ])
                          : _c("div", [
                              _c("img", {
                                staticClass: "gallery",
                                attrs: {
                                  width: "24px",
                                  height: "24px",
                                  alt: "placeholder",
                                  src: require("@/assets/audiogram/gallery.png"),
                                },
                              }),
                              _c("div", [
                                _c("strong", [
                                  _vm._v(
                                    "Choose " +
                                      _vm._s(
                                        _vm.libMedia === "image"
                                          ? "Image"
                                          : "Video"
                                      )
                                  ),
                                ]),
                                _vm._v(" or drag it here\n              "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.libMedia === "image"
                                        ? "Supports: JPG, PNG"
                                        : "Supports: Mp4"
                                    )
                                ),
                              ]),
                            ]),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.media === "image" && _vm.currentContentTab === "STOCK"
            ? _c("div", { staticClass: "flex items-center W-100 mh3 mv2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                  },
                  _vm._l(_vm.imageSearchLibraries, function (item) {
                    return _c(
                      "label",
                      {
                        staticClass: "f6 pv1 ph2 silver link pointer mr1",
                        class:
                          _vm.selectedImageTab === item.LibraryName
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: {
                            checked: _vm.selectedImageTab === item.LibraryName,
                          },
                          on: {
                            click: function ($event) {
                              _vm.selectedImageTab = item.LibraryName
                            },
                          },
                        }),
                        _vm._v(_vm._s(item.name)),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.media === "video" && _vm.currentContentTab === "STOCK"
            ? _c(
                "div",
                { staticClass: "flex items-center items-center W-100 mh3 mv2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                    },
                    _vm._l(_vm.videoSearchLibraries, function (item) {
                      return _c(
                        "label",
                        {
                          staticClass: "f6 pv1 ph2 silver link pointer mr1",
                          class:
                            _vm.selectedVideoTab === item.LibraryName
                              ? "ba br4 b--transparent bg-black-80 light-gray"
                              : "",
                        },
                        [
                          _c("input", {
                            staticClass: "input-reset",
                            attrs: { type: "radio" },
                            domProps: {
                              checked:
                                _vm.selectedVideoTab === item.LibraryName,
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedVideoTab = item.LibraryName
                              },
                            },
                          }),
                          _vm._v(_vm._s(item.name) + "\n          "),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm.media === "gif" && _vm.currentContentTab === "STOCK"
            ? _c(
                "div",
                { staticClass: "flex items-center items-center W-100 mh3 mv2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                    },
                    _vm._l(_vm.gifSearchLib, function (item) {
                      return _c(
                        "label",
                        {
                          staticClass: "f6 pv1 ph2 silver link pointer mr1",
                          class:
                            _vm.selectedGifTab === item.LibraryName
                              ? "ba br4 b--transparent bg-black-80 light-gray"
                              : "",
                        },
                        [
                          _c("input", {
                            staticClass: "input-reset",
                            attrs: { type: "radio" },
                            domProps: {
                              checked: _vm.selectedGifTab === item.LibraryName,
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedGifTab = item.LibraryName
                              },
                            },
                          }),
                          _vm._v(_vm._s(item.name) + "\n          "),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          ["STOCK", "LIB"].includes(_vm.currentContentTab)
            ? _c(
                "div",
                { staticClass: "ph3 pb4 overflow-scroll scrollbar h-100" },
                [
                  _vm.isStockImageLoading ||
                  _vm.isLibImageLoading ||
                  _vm.isPublicGifLoading ||
                  _vm.isTenorFetching ||
                  _vm.isLibVideoLoading ||
                  _vm.isPublicVideoLoading ||
                  _vm.isOpenAiFetching
                    ? _c("div", { staticClass: "flex justify-center mt5" }, [
                        _vm._m(0),
                      ])
                    : (_vm.imageList.length === 0 && _vm.media === "image") ||
                      (_vm.imageList.length === 0 &&
                        _vm.currentContentTab === "LIB" &&
                        _vm.libMedia === "image") ||
                      (_vm.gifList.length === 0 && _vm.media === "gif") ||
                      (_vm.videoList.length === 0 && _vm.media === "video") ||
                      (_vm.videoList.length === 0 &&
                        _vm.currentContentTab === "LIB" &&
                        _vm.libMedia === "video")
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-100 flex flex-column justify-center items-center",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/imgae-search.svg"),
                              alt: "",
                              width: "250",
                              height: "250",
                            },
                          }),
                          _c("div", { staticClass: "f5 silver" }, [
                            _vm._v(
                              "Oops! No images found.. try different query"
                            ),
                          ]),
                        ]
                      )
                    : (["UNSPLASH", "GOOGLE", "PIXABAY", "GETTY"].includes(
                        _vm.selectedImageTab
                      ) &&
                        _vm.media === "image" &&
                        _vm.currentContentTab == "STOCK") ||
                      (_vm.currentContentTab === "LIB" &&
                        _vm.libMedia === "image")
                    ? _c(
                        "div",
                        [
                          _c("AudiogramImagesList", {
                            attrs: {
                              selectedRangeStart: _vm.selectedRangeStart,
                              selectedRangeEnd: _vm.selectedRangeEnd,
                              totalImages: _vm.totalImageCount,
                              imageSources: _vm.imageList,
                              selectedTab:
                                _vm.source === "TRANSCRIPT"
                                  ? "NONE"
                                  : "IMAGE_SEARCH",
                              previewImageSrc: _vm.selectedSceneImage,
                              cols: 4,
                            },
                            on: {
                              handleNavigation: _vm.handleNavigation,
                              viewImages: _vm.viewImages,
                              "load-image": _vm.loadImage,
                            },
                          }),
                        ],
                        1
                      )
                    : ["GIPHY", "TRENDING"].includes(_vm.selectedGifTab) &&
                      _vm.media === "gif"
                    ? _c(
                        "div",
                        [
                          _c("AudiogramImagesList", {
                            attrs: {
                              selectedRangeStart: _vm.selectedGifRangeStart,
                              selectedRangeEnd: _vm.selectedGifRangeEnd,
                              totalImages: _vm.totalGifCount,
                              imageSources: _vm.gifList,
                              selectedTab:
                                _vm.source === "TRANSCRIPT"
                                  ? "NONE"
                                  : "IMAGE_SEARCH",
                              previewImageSrc: _vm.selectedSceneImage,
                              cols: 4,
                            },
                            on: {
                              handleNavigation: _vm.handleGifNavigation,
                              viewImages: _vm.viewImages,
                              "load-image": _vm.loadImage,
                            },
                          }),
                        ],
                        1
                      )
                    : ["TENOR"].includes(_vm.selectedGifTab) &&
                      _vm.media === "gif"
                    ? _c(
                        "div",
                        [
                          _c("InfiniteImageList", {
                            attrs: {
                              imageSources: _vm.gifList,
                              selectedTab:
                                _vm.source === "TRANSCRIPT"
                                  ? "NONE"
                                  : "IMAGE_SEARCH",
                              cols: 4,
                            },
                          }),
                          _vm.hasNextTenorGifs &&
                          _vm.selectedGifTab === "TENOR" &&
                          _vm.media === "gif"
                            ? _c(
                                "div",
                                { staticClass: "tc pb3 ph4 mt3" },
                                [
                                  _c("BaseButtonLoadMore", {
                                    attrs: {
                                      text: "Load more",
                                      onClick: _vm.nextTenorGifs,
                                      rootBottomMargin: "800",
                                      onVisible: function () {},
                                      loading: _vm.isFetchingNextTenorGifs,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : (["PIXABAY", "PEXELS"].includes(_vm.selectedVideoTab) &&
                        _vm.media === "video" &&
                        _vm.currentContentTab == "STOCK") ||
                      (_vm.currentContentTab === "LIB" &&
                        _vm.libMedia === "video")
                    ? _c(
                        "div",
                        [
                          _c("AudiogramVideosList", {
                            attrs: {
                              selectedRangeStart: _vm.selectedVideoRangeStart,
                              selectedRangeEnd: _vm.selectedVideoRangeEnd,
                              totalVideos: _vm.totalVideoCount,
                              videoSources: _vm.videoList,
                              selectedTab: "UPLOADS",
                              cols: "4",
                            },
                            on: {
                              handleNavigation: _vm.handleVideoNavigation,
                              "load-video": _vm.loadVideo,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.currentContentTab === "GENERATE"
            ? _c("div", { staticClass: "ph3 pb3" }, [
                _c("div", { staticClass: "relative" }, [
                  _c("div", { staticClass: "flex flex-column relative" }, [
                    _c("div", [
                      _c("div", { staticClass: "relative" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.aiSearchQuery,
                              expression: "aiSearchQuery",
                            },
                          ],
                          ref: "aiTextArea",
                          staticClass:
                            "input-reset bg-adori-light-gray light-gray h2 bn pv2 br2 pl2 pr4 dib w-100 f6 mt2 mb1 scrollbar",
                          staticStyle: { height: "10vh" },
                          attrs: {
                            type: "text",
                            placeholder: "Change Image Prompt",
                          },
                          domProps: { value: _vm.aiSearchQuery },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.aiSearchQuery = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "flex bg-gray br2 ba b--silver ph1 f6 pointer",
                            attrs: { disabled: _vm.isOpenAiFetching },
                            on: { click: _vm.enhancePrompt },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons light-gray f4 v-mid mr2",
                              },
                              [_vm._v("auto_fix_high")]
                            ),
                            _vm._v("Improve prompt\n                  "),
                            _vm.isOpenAiFetching
                              ? _c("div", {
                                  staticClass: "ml2 loader loader-inline",
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex flex-row center space-between" },
                      [
                        _c("span", { staticClass: "f6 silver center pv2" }, [
                          _vm._v("Select orientation"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedOrientation,
                                expression: "selectedOrientation",
                              },
                            ],
                            staticClass:
                              "bg-adori-gray white f6 br2 h2 ba b--gray mh2",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedOrientation = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {},
                              ],
                            },
                          },
                          _vm._l(_vm.orientations, function (item, i) {
                            return _c(
                              "option",
                              {
                                key: i,
                                domProps: { value: item.aspect_ratio },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.title) +
                                    "\n                "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("span", { staticClass: "f6 silver center pv2" }, [
                          _vm._v("Select AI Model"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedModel,
                                expression: "selectedModel",
                              },
                            ],
                            staticClass:
                              "bg-adori-gray white f6 br2 h2 ba b--gray mh2",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedModel = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {},
                              ],
                            },
                          },
                          _vm._l(_vm.imageModels, function (item, i) {
                            return _c(
                              "option",
                              {
                                key: i,
                                attrs: {
                                  disabled:
                                    _vm.isFreeAccount &&
                                    item.value === "essential-v2",
                                },
                                domProps: { value: item.value },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.title) +
                                    " " +
                                    _vm._s(
                                      _vm.isFreeAccount &&
                                        item.value === "essential-v2"
                                        ? "🔒"
                                        : ""
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("BaseButtonRed", {
                          staticClass: "z-999 h2 center",
                          attrs: {
                            text: "Generate",
                            onClick: _vm.searchAiImages,
                            disabled:
                              _vm.isStabilityLoading || _vm.isOpenAiFetching,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm.isStabilityLoading
                  ? _c(
                      "div",
                      { staticClass: "flex justify-center mt5 relative" },
                      [
                        _vm._m(1),
                        _c("div", { staticClass: "absolute pt5 mt5 silver" }, [
                          _vm._v(
                            "Generating AI image, may take upto 30 seconds..."
                          ),
                        ]),
                      ]
                    )
                  : _vm.showAISceneText
                  ? _c("div", { staticClass: "relative" }, [
                      _c("div", { staticClass: "flex flex-column relative" }, [
                        !!_vm.aiImg
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap justify-center relative mt4",
                              },
                              [
                                _c("img", {
                                  staticClass: "pa2 pointer grow",
                                  attrs: {
                                    title: "select image",
                                    src: _vm.aiImg,
                                    height: "350",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectAiImage(_vm.aiImg)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          (_vm.currentContentTab === "GENERATE" && !_vm.isStabilityLoading) ||
          (_vm.currentContentTab === "GENERATE" &&
            !_vm.isStabilityLoading &&
            !_vm.openAiRes)
            ? _c("div", { staticClass: "flex gap-1 ph3" }, [
                !_vm.aiImg
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex w-100 justify-center items-center flex-column",
                      },
                      [
                        _c("img", {
                          staticClass: "br4",
                          attrs: {
                            width: "300",
                            src: "https://storage.googleapis.com/dev_image_adorilabs/static/ai-drawing-image.jpeg",
                          },
                        }),
                        _c("div", { staticClass: "silver mt4 f5" }, [
                          _vm._v(
                            "Generated image will be automatically added to My Library"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _vm.showImagePreview
        ? _c("ModalPreviewImages", {
            attrs: { image: _vm.image },
            on: {
              close: function ($event) {
                _vm.showImagePreview = false
              },
            },
          })
        : _vm._e(),
      _vm.showPlans
        ? _c("ModalPlans", {
            attrs: { customModal: "" },
            on: {
              closeModal: function ($event) {
                _vm.showPlans = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "building-blocks mt5" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "building-blocks mt5" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }